exports.components = {
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-fotografie-exterier-js": () => import("./../../../src/pages/fotografie-exterier.js" /* webpackChunkName: "component---src-pages-fotografie-exterier-js" */),
  "component---src-pages-fotografie-js": () => import("./../../../src/pages/fotografie.js" /* webpackChunkName: "component---src-pages-fotografie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-chalupe-js": () => import("./../../../src/pages/o-chalupe.js" /* webpackChunkName: "component---src-pages-o-chalupe-js" */),
  "component---src-pages-okoli-js": () => import("./../../../src/pages/okoli.js" /* webpackChunkName: "component---src-pages-okoli-js" */),
  "component---src-pages-ubytovaci-rad-js": () => import("./../../../src/pages/ubytovaci-rad.js" /* webpackChunkName: "component---src-pages-ubytovaci-rad-js" */)
}

